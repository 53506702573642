import { useLazyQuery, useQuery } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { EmbeddedWithMigrationQuery } from './EmbeddedWithMigrationQuery.graphql';
import type {
	EmbeddedWithMigrationQueryVariables,
	EmbeddedWithMigrationQuery as EmbeddedWithMigrationQueryType,
} from './__types__/EmbeddedWithMigrationQuery';

let onMigrationSuccess: (data?: any) => void = () => {};
let onMigrationFailure: (err?: any) => void = () => {};

export const useEmbeddedMigrationQuery = (contentId: string, skipOnLoadQuery: boolean) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const trackMigrationEvent = ({
		action,
		actionSubjectId,
		withMigration,
		error,
	}: {
		action: 'completed' | 'failed';
		actionSubjectId: 'ncsMigrationOnLoad' | 'ncsMigrationOnEditClick';
		withMigration?: boolean;
		error?: string;
	}) => {
		return createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action,
				actionSubject: 'ncsMigrationQuery',
				actionSubjectId,
				source: 'embeddedPageViewScreen',
				object: {
					id: contentId,
					type: 'page',
				},
				attributes: {
					withMigration,
					error,
				},
			},
		}).fire();
	};

	const epMigrationOnLoad = useQuery<
		EmbeddedWithMigrationQueryType,
		EmbeddedWithMigrationQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		EmbeddedWithMigrationQuery,
		{
			variables: {
				contentId,
			},
			fetchPolicy: 'network-only',
			skip: skipOnLoadQuery,
			onCompleted: (data) => {
				if (data?.content?.nodes?.[0]?.metadata?.frontend?.embeddedWithMigration ?? false) {
					trackMigrationEvent({
						action: 'completed',
						actionSubjectId: 'ncsMigrationOnLoad',
						withMigration: true,
					});
				} else {
					trackMigrationEvent({
						action: 'completed',
						actionSubjectId: 'ncsMigrationOnLoad',
						withMigration: false,
					});
				}
			},
			onError: (err) => {
				trackMigrationEvent({
					action: 'failed',
					actionSubjectId: 'ncsMigrationOnLoad',
					error: err.message,
				});
			},
		},
	);

	const [migrateData, { data: onClickData, loading: onClickLoading, error: onClickError }] =
		useLazyQuery<EmbeddedWithMigrationQueryType, EmbeddedWithMigrationQueryVariables>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			EmbeddedWithMigrationQuery,
			{
				variables: {
					contentId,
				},
				fetchPolicy: 'network-only',
				onCompleted: (data) => {
					if (data?.content?.nodes?.[0]?.metadata?.frontend?.embeddedWithMigration ?? false) {
						trackMigrationEvent({
							action: 'completed',
							actionSubjectId: 'ncsMigrationOnEditClick',
							withMigration: true,
						});
						onMigrationSuccess(onClickData);
					} else {
						trackMigrationEvent({
							action: 'completed',
							actionSubjectId: 'ncsMigrationOnEditClick',
							withMigration: false,
						});
						onMigrationFailure();
					}
				},
				onError: (err) => {
					trackMigrationEvent({
						action: 'failed',
						actionSubjectId: 'ncsMigrationOnEditClick',
						error: err?.message,
					});
					onMigrationFailure(err);
				},
			},
		);

	const migrateEPData = (
		onSuccess: (data?: any) => void = () => {},
		onFailure: (err?: any) => void = () => {},
	) => {
		onMigrationSuccess = onSuccess;
		onMigrationFailure = onFailure;
		migrateData();
	};

	return {
		epMigrationOnLoad,
		epMigrationOnClick: {
			migrateEPData,
			data: onClickData,
			loading: onClickLoading,
			error: onClickError,
		},
	};
};
